<template>
  <v-app>
    <div class="connection-refused-page" :style="inlineStyles">
      <h1 class="connection-refused-page__title">
        {{ $t('error503.title') }}
        <br />
        {{ $t('error503.subtitle') }}
      </h1>

      <router-link :to="{ name: 'root' }" class="connection-refused-page__link">
        {{ $t('error503.back') }}
      </router-link>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'ConnectionRefusedErrorPage',
  computed: {
    inlineStyles() {
      const mobImg = require('@/assets/errors/connection-refused-mobile.svg');
      const deskImg = require('@/assets/errors/connection-refused-desktop.svg');

      return {
        backgroundImage: `url(${this.$mq === 'hr' ? deskImg : mobImg})`,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.connection-refused-page
  height: 100vh
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  padding: base-unit(128) 0
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center

  +medium-devices
    padding: base-unit(100) 0

  &__title
    color: white
    font-weight: 400
    text-align: center
    font-size: base-unit(16)
    +medium-devices
      font-size: base-unit(18)
      font-weight: 700

  &__link
    padding: 8px 20px
    text-decoration: none
    color: white
    font-weight: 500
    border: 1px solid #FFFFFF
    filter: drop-shadow(0px 2px 4px rgba(22, 126, 162, 0.075))
    border-radius: 4px
</style>
